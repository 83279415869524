<template>
	<div class="wrapper transformer">

		<div>本月登录次数：<span style="font-weight: bold; font-size: 16px; color: #318fdf; margin-right: 3px;">{{ fenye.totalnum }}</span>次</div>
		<!-- 列表 -->
		<el-table :data="tableData" border stripe style="width: 100%; margin-top: 10px;" @cell-click="getItem">
			<el-table-column type="index" label="序号">
    	</el-table-column>
			<el-table-column prop="create_time" label="登录时间" align="center">
			</el-table-column>
		</el-table>


		<div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
		
		
	</div>

</template>

<script>
import { getLogger} from '@/api/system'
	export default {
		data() {
			return {
				tableData: [],
				userDialogVisible: false,
				currentPage: 1,
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10
				},
				list: []
			
			}
		},
		props:{
			logUserId: {
				type: String,
				default: ''
			}
		},
		mounted() {
			this.search();
		},
		methods: {
      getItem(row){
        console.log(row)
        this.$emit("getCopyUserId", row)
      },
			
			//列表查询
			search() {		
				this.currentPage = 1;		
				this.getList();
			},
			
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},

			//获取用户列表
			getList() {
				getLogger({
						userid: this.GLOBAL.adminId(),
						sysuserid: this.logUserId,
						page: this.currentPage,
						pagesize: this.fenye.pagesize
				}).then(res=>{
					if(res.result == 200){
						let detail = res.detail;
						let list = detail.list;
						this.fenye.totalnum = detail.totalRow;
						this.tableData = list;
					} else {
						this.$message.error(res.description);
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">

.el-select{
	width: 100%;
}
.paginationbox{
	text-align: right;
	margin-top: 20px;
}
	


	.el-textarea .el-textarea__inner {
		resize: none;
	}

	.loudong{
		.tree{
			height: 600px;
			overflow: auto;
		}
		.btns{
			margin-top: 20px;
			text-align: center;
		}
	}
</style>
